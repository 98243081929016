<template>
  <div>
    <el-dialog :visible.sync="dialog" title="转移支付单" append-to-body :close-on-click-modal="false" @closed="handleClosed" custom-class="custom-dialog-max-width">
      <div>将已关闭或取消的销售订单的支付单据转移或部分金额拆分至当前销售订单中。</div>
      <div style="margin: 10px -20px;" class="padding-20 bc-l" v-if="order">
        <div class="fc-g fs-small">
          <b>当前销售单信息</b>
        </div>
        <div class="h c">
          <form-info-item label="销售单号：" label-position="right" label-width="auto" style="margin-right: 100px;">{{order.formCode}}</form-info-item>
          <form-info-item label="商场合同号：" label-position="right" label-width="auto" style="margin-right: 100px;">{{order.contractNo || "-"}}</form-info-item>
          <form-info-item label="订单金额" label-position="right" label-width="auto">{{$price(order.realTotalMoney)}}</form-info-item>
        </div>
      </div>
      <div class="h s">
        <div style="width: 50%; margin-right: 20px;">
          <div class="padding-10-0 fc-g">请选择来源销售订单：</div>
          <div class="head-container">
            <el-input v-model="query.keywords" class="filter-item" :maxlength="20" placeholder="销售单号/合同号/电话号码搜索" @keypress.enter.native="toQuery" style="width: 220px;" />
            <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
          </div>
          <el-table ref="list" :data="data" border highlight-current-row @current-change="handleCurrentChange">
            <el-table-column prop="formCode" label="销售单号" min-width="140" fixed />
            <el-table-column prop="contractNo" label="商场合同号" min-width="100" show-overflow-tooltip />
            <el-table-column prop="customerName" label="客户名称" min-width="100" />
            <el-table-column prop="customerPhone" label="客户电话" min-width="100" show-overflow-tooltip />
            <el-table-column prop="realTotalMoney" label="总金额" min-width="120" align="right" :formatter="$price" />
            <el-table-column label="已收款" min-width="120" align="right">
              <template slot-scope="scope">
                <span :class="scope.row.payAmount >= scope.row.realTotalMoney ? 'fc-s' : ''">{{$price(scope.row.payAmount)}}</span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
        </div>
        <div class="flex">
          <div class="padding-10-0 fc-g">请选择支付单：</div>
          <el-table ref="sub" :data="store" border @selection-change="handleSelectionChange" empty-text="请选择左侧的销售订单，或者当前订单没有支付单据。" v-loading="loading">
            <el-table-column type="selection" width="40" />
            <el-table-column label="收/退款单号" prop="code" min-width="130" />
            <el-table-column label="费用类型" :formatter="r => { return feeTypes[r.feeType] }" min-width="80" />
            <el-table-column label="支付日期" :formatter="r => { return new Date(r.payTime).format('yyyy/MM/dd'); }" min-width="80" />
            <el-table-column label="支付类型" :formatter="r => { return r.amount >= 0 ? '付款' : '退款' }" min-width="80" />
            <el-table-column prop="amount" label="支付金额" :formatter="$price" min-width="100" align="right" />
            <el-table-column width="50" v-if="current && (current.status === 'closedPart' || current.status === 'closed' || current.status === 'cancel') && current.payAmount > 0">
              <div class="row-commands" slot-scope="scope">
                <el-button size="mini" type="text" @click="split(scope.row, true)" v-if="scope.row.amount > 0">拆分</el-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="submiting" :disabled="!selection || !selection.length" type="primary" @click="doSubmit">确认转移</el-button>
      </div>
    </el-dialog>
    <pay-split ref="split" :order="order" @complete="handleSplitComplete" />
  </div>
</template>

<script>
import paySplit from "./pay-split";
import initData from "@/mixins/initData";
import request from "@/utils/request";

export default {
  components: { paySplit },
  mixins: [initData],
  props: {
    order: Object,
  },
  data() {
    return {
      dialog: false,
      submiting: false,
      current: null,
      selection: null,
      loading: false,
      store: [],
      query: {
        keywords: "",
      },
      feeTypes: {
        orderForm: "订单费用",
        freight: "运费",
        repair: "维修费",
        other: "其他",
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    beforeInit() {
      this.url = "api/shop/form";
      this.params = Object.assign(
        { statusList: ["cancel", "closed", "closedPart"] },
        this.query
      );
      return true;
    },
    handleClosed() {
      this.current = null;
      this.selection = null;
      this.store = [];
      this.$refs.list && this.$refs.list.setCurrentRow();
      this.$refs.sub && this.$refs.sub.clearSelection();
    },
    handleCurrentChange(row) {
      this.current = row;
      this.loadPays();
    },
    handleSplitComplete() {
      this.loadPays();
      this.$emit("complete");
    },
    loadPays() {
      if (this.current) {
        this.selection = null;
        this.store = [];
        this.loading = true;
        request({
          url: "api/shop/form/pay/" + this.current.id,
          method: "get",
        })
          .then((res) => {
            this.store = res;
          })
          .finally((_) => {
            this.loading = false;
          });
      }
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    open() {
      this.dialog = true;
    },
    split(row) {
      this.$refs.split && this.$refs.split.resetForm(row);
    },
    doSubmit() {
      this.$confirm(
        "当前操作无法取消和撤回！您确定要将所选的支付单据转移至当前销售订单中吗？",
        "操作提示",
        {
          type: "warning",
        }
      ).then((_) => {
        this.submiting = true;
        request({
          url: "api/shop/form/pay/change",
          method: "post",
          data: {
            payFormIds: this.selection.map((o) => {
              return o.id;
            }),
            newFormId: this.order.id,
          },
        }).then((res) => {
          this.$notify({
            title: "支付单转移成功",
            type: "success",
            duration: 2500,
          });
          this.$emit("complete");
          this.dialog = false;
        }).finally(_=>{
          this.submiting = false;
        }
        );
      });
    },
  },
};
</script>