<template>
  <el-dialog :visible.sync="dialog" title="拆分支付单" append-to-body :close-on-click-modal="false" @closed="handleClosed">
    <div>
      将当前支付单进行拆分，将在当前销售单新增收款单，并且当前支付单所属销售单将自动增加退款单。
      <br />
      <span class="fc-e">注意：自动生成的支付单据将没有对应的收/退款凭证和票据图片等。</span>
    </div>
    <template v-if="pay">
      <div style="margin: 10px -20px;" class="padding-20 bc-l">
        <div class="fc-g fs-small">
          <b>当前支付单</b>
        </div>
        <div class="h c">
          <form-info-item label="收款单号：" label-position="right" label-width="auto" style="margin-right: 100px;">{{pay.formCode}}</form-info-item>
          <form-info-item label="支付金额：" label-position="right" label-width="auto">{{$price(pay.amount)}}</form-info-item>
        </div>
      </div>
      <div class="padding-10-0">请填写要拆分出去的金额：</div>
      <div class="h c">
        <price-input v-model="money" :min="0" :max="pay.amount / 100" />&emsp;
        <el-button type="primary" :disabled="isNaN(money) || money <= 0" :loading="submiting" @click="doSplit">确认拆分</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    order: Object,
  },
  data() {
    return {
      dialog: false,
      pay: null,
      money: 0,
      submiting: false,
    };
  },
  methods: {
    handleClosed() {
      this.pay = null;
      this.money = 0;
    },
    resetForm(pay) {
      if (pay && !isNaN(pay.amount) && pay.amount > 0) {
        this.pay = pay;
        this.dialog = true;
      }
    },
    doSplit() {
      this.$confirm(
        "当前操作无法取消和撤回！您确定要将当前支付单据拆分至当前销售订单中吗？",
        "操作提示",
        {
          type: "warning",
        }
      ).then((_) => {
        this.submiting = true;
        request({
          url: "api/shop/form/pay/split",
          method: "post",
          data: {
            newFormId: this.order.id,
            payFormId: this.pay.id,
            changeMoney: this.money,
          },
        })
          .then((res) => {
            this.$notify({
              title: "支付单拆分成功",
              type: "success",
              duration: 2500,
            });
            this.$emit("complete");
            this.dialog = false;
          })
          .finally((_) => {
            this.submiting = false;
          });
      });
    },
  },
};
</script>